<template>
  <div class="">
    <TopBarTitleComponent showBackIcon title="Servicios Adicionales" />
    <b-container fluid class="pb-4 px-lg-4">
      <template v-if="apiResponse.success">
        <template v-if="items.length">
          <section class="input-filters"></section>

          <paginate
            class="wrapper-pagination"
            name="resultQueryContacts"
            :list="resultQueryContacts"
            :per="perPaginate"
            :key="searchQuery"
          >
            <div class="card-content-items">
              <b-row>
                <b-col
                  xs="12"
                  sm="6"
                  md="6"
                  lg="4"
                  xl="3"
                  v-for="(item, index) in paginated('resultQueryContacts')"
                  :key="index"
                >
                  <div class="card card-shadow mb-4">
                    <div class="card-body card-shadow-body">
                      <div class="business-content-item p-2">
                        <div class="business-description-item">
                          <h3 class="h3 font-bold mb-0 str-limit-space">
                            {{ item.name }}
                          </h3>
                          <small class="small">{{ item.legend }}</small>
                        </div>
                        <div
                          style="display: grid; grid-template-columns: auto auto;"
                        >
                          <h2 class="font-bold">${{ item.amount }}</h2>
                          <small
                            class="small font-bold"
                            style="margin-top: 15px;"
                            >+iva</small
                          >
                        </div>
                      </div>
                      <div class="business-content-item ">
                        <div class="business-description-item">
                          <WrapperTooltipComponent :content="item.description">
                            <el-tag
                              type="default"
                              :class="`status success border-radius font-bold`"
                            >
                              <span class="font-bold">¿Qué es?</span>
                            </el-tag>
                          </WrapperTooltipComponent>
                        </div>
                        <div class="text-right">
                          <ButtonPrimaryComponent
                            title="Pagar"
                            @onSaveClick="payAdditional(item)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </paginate>
          <paginate-links
            v-show="resultQueryContacts.length > perPaginate"
            for="resultQueryContacts"
            :classes="{ ul: 'pagination', li: 'page-item', a: 'page-link' }"
            :simple="{
              next: 'Siguiente »',
              prev: '« Regresar'
            }"
          ></paginate-links>
        </template>
        <EmptyPage
          :media="emptyMedia1"
          text="Aún no se ha registrado servicios adicionales para comprar."
          v-else
        />
      </template>
      <SkeletonLoadingCard v-else />
    </b-container>
    <!-- dialog to add new contact -->
  </div>
</template>

<script>
import TopBarTitleComponent from "../../components/TopBarTitle";
import SkeletonLoadingCard from "@/components/Animations/Loading/SkeletonCard";
import EmptyPage from "../../../dashboard/components/EmptyPage";
import { emptyMedia1 } from "@/services/resources";
import payService from "../services/payService";
import ButtonPrimaryComponent from "@/modules/dashboard/components/ButtonPrimary";
import WrapperTooltipComponent from "../../../dashboard/components/WrapperTooltip";

export default {
  name: "AdditionalsComponent",
  data: () => ({
    dialogEditContact: false,
    contact: {},
    emptyMedia1,
    apiResponse: {
      success: false
    },
    searchQuery: null,
    orderByQuery: null,
    paginate: ["resultQueryContacts"],
    perPaginate: 12,
    items: []
  }),
  computed: {
    resultQueryContacts() {
      let clients = this.items;
      return clients
        .concat()
        .sort((a, b) => {
          switch (this.orderByQuery) {
            case "recent":
              return a.id < b.id ? 1 : -1;
            case "old":
              return a.id > b.id ? 1 : -1;
            case "a-z":
              return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            case "z-a":
              return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
          }
        })
        .filter(item =>
          this.searchQuery
            ? item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
            : item
        );
    }
  },
  methods: {
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    onChangeSearch({ search }) {
      this.searchQuery = search;
    },
    onSelectOrderBy({ orderBy }) {
      this.orderByQuery = orderBy;
    },
    async payAdditional(item) {
      try {
        this.$store.dispatch("toggleRequestLoading", {
          text: "Por favor espere un momento, estamos validando la transacción."
        });
        const datos = {
          additional_id: item.id
        };
        let response = await payService.postPayAdditional(datos).finally(() => {
          this.$store.dispatch("toggleRequestLoading");
        });
        const { success, data: checkOutPayphone } = response.data;
        if (success) {
          if (checkOutPayphone) {
            location.href = checkOutPayphone;
          } else {
            this.$notifications.error({});
          }
        }
      } catch (error) {
        return false;
      }
    },
    onSuccessContactEdit() {
      this.dialogEditContact = false;
      this.contact = {};
      this.getAdditionals();
    },
    handleCloseContactEdit() {
      this.dialogEditContact = false;
    },
    async getAdditionals() {
      try {
        let response = await payService.getPayAdditionals();
        const { success, data } = response.data;
        if (success) {
          this.items = data;
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    // InputSearchComponent,
    // SelectOrderByComponent,
    SkeletonLoadingCard,
    EmptyPage,
    TopBarTitleComponent,
    ButtonPrimaryComponent,
    WrapperTooltipComponent
  },
  mounted() {
    this.getAdditionals();
  }
};
</script>

<style lang="scss" scoped>
.card-content-items {
  .business-content-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .business-image-item {
      margin-right: 1rem;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
  }
}
</style>
